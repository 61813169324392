<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Message } from "../../../types/Message";
import { Contact } from "../../../types/Contact";
import { formatDateForChat } from "../../../helpers";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
    chat: {
      type: Object as PropType<Contact>,
      required: true,
    },
  },
  setup() {
    return { formatDateForChat };
  },
});
</script>
<template>
  <div
    class="chat-content mb-2"
    v-if="message.subtype == 'offer' || message.subtype == 'reject'"
  >
    <div class="message-content" :id="'content-' + message.msgId">
      <div class="file-download d-flex align-items-center">
        <div
          class="file-type d-flex align-items-center justify-content-center me-2"
        >
          <i v-if="message.subtype == 'offer'" class="fas fa-phone-alt"></i>
          <i v-else class="fas fa-phone-slash"></i>
        </div>
        <div class="file-details">
          <span class="file-name">Ligação de voz/video</span>
          <span class="file-size">{{
            message.subtype == "offer"
              ? "Recebendo ligação"
              : message.subtype == "reject"
              ? "Sem resposta"
              : "Desconhecida"
          }}</span>
        </div>
      </div>
      <div class="chat-time">
        <div>
          <div class="time">
            <i class="fas fa-clock"></i>
            {{ formatDateForChat(message.timestamp) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
