<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { formatDateForChat } from "../../../helpers";
import { useStore } from "../../../stores/dataStore";
import { Message } from "../../../types/Message";
import { Contact } from "../../../types/Contact";

import MessageDeletedComponent from "./MessageDeletedComponent.vue";
import ReplyMessage from "./ReplyMessageChatMessage.vue";
import ChatBubbleInfo from "./ChatBubbleInfo.vue";
import moment from "moment";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
    showDeletedMessage: {
      type: Boolean,
      required: true,
    },
    chat: {
      type: Object as PropType<Contact>,
      required: true,
    },
    pic: {
      type: String,
      required: true,
    },
    pause: {
      type: Boolean,
      required: false,
      defaultValue: false,
    },
    msgIdForAutoplay: {
      type: String,
      required: false,
    },
  },
  components: {
    ReplyMessage,
    MessageDeletedComponent,
    ChatBubbleInfo,
  },
  data() {
    return {
      audio: new Audio(),
      audioUrl: "",
      paused: true,
      loading: false,
    };
  },
  setup() {
    return {
      formatDateForChat,
      User: () => useStore(),
      moment,
    };
  },
  watch: {
    ["message.path"]: {
      handler(val) {
        if (!this.paused) return;
        if (val) {
          this.createAudioAttrs();
        } else {
          this.loading = true;
        }
      },
    },
    pause: {
      handler(val) {
        if (val === true) {
          this.audio?.pause();
          this.$emit("setPause", false);
        }
      },
    },
    msgIdForAutoplay: {
      handler(msgId) {
        if (msgId === this.message.msgId) {
          this.handlePlayButton();
        }
      },
    },
  },
  methods: {
    setDeletedMessageView(value: boolean) {
      this.$emit("setDeletedMessageView", value);
    },
    setReply(message: Message) {
      this.$emit("setReply", message);
    },
    setBitrate() {
      if (this.User().audioBitRate >= 2.5) {
        this.User().audioBitRate = 1;
      } else {
        this.User().audioBitRate += 0.5;
      }
      this.audio.playbackRate = this.User().audioBitRate;
    },
    handlePlayButton() {
      if (!this.paused) {
        this.audio?.pause();
        this.paused = this.audio.paused;
      } else {
        this.$emit("setPause", true);
        setTimeout(() => {
          this.audio?.play();
          this.paused = this.audio.paused;
        }, 100);
      }
    },

    formatTimeToDisplay(seconds: number) {
      if (seconds == Infinity) seconds = 0;
      const milliseconds = seconds * 1000;
      return new Date(milliseconds).toISOString().substr(14, 5);
    },
    showTimeDuration() {
      const { duration } = this.audio;
      const durationDisplay = this.formatTimeToDisplay(duration);
      this.updateCurrentTimeDisplay(durationDisplay);
    },
    handleSlider(e: any) {
      const { duration } = this.audio;
      const percent = e.target.value;
      const currentTimeInSeconds = ((percent * duration) / 100).toFixed(2);
      this.audio.currentTime = parseFloat(currentTimeInSeconds);
    },

    updateCurrentTimeDisplay(time: string) {
      const audioPlayer = document.getElementById(
        "audio-player-" + this.message.id
      );
      if (!audioPlayer) return;
      audioPlayer!.style.setProperty("--player-current-time", `'${time}'`);
    },

    updateCurrentPercent() {
      const audioPlayer = document.getElementById(
        "audio-player-" + this.message.id
      );
      const slider = document.getElementById("slider-" + this.message.id);
      const { currentTime, duration } = this.audio;
      const percentPlayed = (currentTime * 100) / duration;
      (slider as any)!.value = percentPlayed;
      audioPlayer!.style.setProperty(
        "--player-percent-played",
        `${percentPlayed}%`
      );
    },
    createAudioAttrs() {
      this.audioUrl = this.message.path?.includes("base64")
        ? this.message.path
        : "https://titanchat-uploads.s3.sa-east-1.amazonaws.com/" +
          this.message.path;
      this.audio = new Audio(this.audioUrl);
      this.audio.playbackRate = this.User().audioBitRate;

      this.audio.onloadstart = () => (this.loading = true);
      this.audio.onloadeddata = () => (this.loading = false);
      this.audio.onpause = () => (this.paused = true);
      this.audio.ondurationchange = this.showTimeDuration;
      this.audio.onended = () => {
        this.audio.currentTime = 0;
        this.paused = true;
        this.$emit("playNextAudio");
      };
      this.audio.ontimeupdate = () => {
        const { currentTime } = this.audio;
        const currentTimeDisplay = this.formatTimeToDisplay(currentTime);
        this.updateCurrentTimeDisplay(currentTimeDisplay);
        this.updateCurrentPercent();
        if (currentTime === 0) {
          this.showTimeDuration();
        }
      };
    },
  },
  created() {
    if (this.message.path) {
      this.createAudioAttrs();
    } else {
      this.loading = true;
    }
  },
});
</script>
<template>
  <div
    class="chat-content"
    @dblclick="setReply(message as any)"
    style="background-color: transparent"
  >
    <ReplyMessage :message="message" @click="$emit('getMessageReplyed')" />
    <MessageDeletedComponent
      :message="message"
      :showDeletedMessage="showDeletedMessage"
      @viewDeletedMessage="setDeletedMessageView(true)"
    />
    <div
      class="message-content"
      style="background-color: transparent"
      :id="'content-' + message.msgId"
      :class="{ 'd-none': message.ack === '-8' && !showDeletedMessage }"
    >
      <ChatBubbleInfo :message="message" />
      <div
        :class="{ 'audio-player': true, loading: loading }"
        :id="'audio-player-' + message.id"
        v-if="message.fromMe"
        :data-url="
          message.path?.includes('base64')
            ? message.path
            : 'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
              message.path
        "
      >
        <div class="player">
          <button type="button" class="btn-play" @click="handlePlayButton()">
            <span
              class="material-icons icon-play"
              v-show="this.paused && !this.loading"
              >play_arrow</span
            >
            <span class="material-icons icon-pause" v-show="!this.paused"
              >pause</span
            >
            <span class="material-icons icon-loop" v-show="this.loading"
              >loop</span
            >
          </button>
          <div class="timeline">
            <div class="line">
              <input
                dir="ltr"
                :id="'slider-' + this.message.id"
                @input="handleSlider"
                type="range"
                min="0"
                max="100"
                value="0"
              />
            </div>
            <div class="data">
              <div class="current-time"></div>
              <div class="time">
                {{ moment.unix(message.timestamp).format("HH:mm") }}
                <div
                  class="chat-read-col"
                  style="color: #eb4034; cursor: pointer"
                  v-if="message.ack == '-1' && message.userIdSend"
                >
                  <span
                    class="material-icons"
                    title="Falha no envio da mensagem..."
                    >error</span
                  >
                </div>
                <div
                  class="chat-read-col"
                  style="color: #13af28; cursor: pointer"
                  v-if="message.ack == '9' && message.userIdSend"
                >
                  <span class="material-icons" title="Mensagem agendada"
                    >update</span
                  >
                </div>
                <div
                  class="chat-read-col"
                  style="color: #46484d"
                  v-else-if="message.ack == '0' && message.userIdSend"
                >
                  <span class="material-icons">update</span>
                </div>
                <div
                  class="chat-read-col"
                  style="color: #46484d"
                  v-else-if="message.ack == '1' && message.userIdSend"
                >
                  <span class="material-icons">done</span>
                </div>
                <div
                  class="chat-read-col"
                  style="color: #46484d"
                  v-else-if="message.ack == '2' && message.userIdSend"
                >
                  <span class="material-icons">done_all</span>
                </div>
                <div
                  class="chat-read-col"
                  style=""
                  v-else-if="message.ack == '3' && message.userIdSend"
                >
                  <span class="material-icons">done_all</span>
                </div>
                <div
                  class="chat-read-col"
                  v-else-if="message.ack == '4' && message.userIdSend"
                >
                  <span class="material-icons">volume_up</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="user" @click="setBitrate()">
          <span class="playback_rate_value" v-if="!this.paused"
            >{{ User().audioBitRate }} x</span
          >
          <img :src="pic" />
          <span class="material-icons">mic</span>
        </div>
      </div>
      <!-- Fim player -->

      <!-- Player -->
      <div
        :class="{ 'audio-player': true, mine: true, loading: loading }"
        :id="'audio-player-' + message.id"
        v-if="!message.fromMe"
      >
        <div class="user" @click="setBitrate()">
          <span
            v-show="this.paused === false"
            class="playback_rate_value playback_rate_value_right"
            >{{ User().audioBitRate }}x</span
          >
          <img :src="pic" />
          <span class="material-icons">mic</span>
        </div>
        <div class="player">
          <button type="button" class="btn-play" @click="handlePlayButton()">
            <span
              class="material-icons icon-play"
              v-show="this.paused && !this.loading"
              >play_arrow</span
            >
            <span class="material-icons icon-pause" v-show="!this.paused"
              >pause</span
            >
            <span class="material-icons icon-loop" v-show="this.loading"
              >loop</span
            >
          </button>
          <div class="timeline">
            <div class="line">
              <input
                dir="ltr"
                :id="'slider-' + this.message.id"
                @input="handleSlider"
                type="range"
                min="0"
                max="100"
                value="0"
              />
            </div>
            <div class="data">
              <div class="current-time"></div>
              <div class="time">
                {{ moment.unix(message.timestamp).format("HH:mm") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="transcribe"
        v-show="message.content"
        style="font-size: 9pt; font-style: italic"
      >
        Transcrição: {{ message.content }}
      </div>
    </div>
    <div class="message-reaction" v-if="message.reactions">
      <h6>{{ message.reactions }}</h6>
    </div>
  </div>
</template>
