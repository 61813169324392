<script lang="ts">
import { defineComponent } from "vue";
import { sendPoll } from "../../helpers/message";

export default defineComponent({
  props: ["chat_opened"],
  data() {
    return {
      name: "",
      selectableCount: true,
      options: [
        {
          id: 0,
          text: "",
          placeholder: "Opção 1",
        },
        {
          id: 0,
          text: "",
          placeholder: "Opção 2",
        },
      ],
    };
  },
  methods: {
    addItemOption() {
      if (this.options.length > 2)
        this.options = this.options.filter((item: any) => item.text !== "");
      if (this.options.length >= 12) return;
      const preLast = this.options[this.options.length - 1];
      if (preLast?.text?.length > 0) {
        this.options.push({
          id: this.options.length + 1,
          text: "",
          placeholder: `Opção ${this.options.length + 1}`,
        });
      }
    },
    sendPollMessage() {
      const options: any = [];
      this.options.map((item: any) => {
        if (item.text?.length > 0) options.push(item.text);
      });
      sendPoll(
        this.chat_opened.contact_type,
        this.chat_opened.id_api,
        this.chat_opened.id,
        this.name,
        options,
        {
          selectableCount: !this.selectableCount ? 1 : undefined,
        }
      );
    },
    isValidPoll(): boolean {
      const optionsValid = this.options.filter((item: any) => item.text !== "");
      return optionsValid.length > 0;
    },
  },
});
</script>
<template>
  <!-- Modal - Enviar enquete -->
  <div class="modal fade" id="send-poll">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="material-icons">list</span>Enviar enquete
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- Card -->
          <form style="display: block">
            <div class="col-12">
              <div class="settings-control p-3" style="border: 0">
                <li class="d-flex align-items-center">
                  <label class="switch me-3">
                    <input
                      type="checkbox"
                      v-model="selectableCount"
                      id="rejectCall"
                    />
                    <span class="slider round"></span>
                  </label>
                  <div>
                    <span>Habilitar múltiplas respostas</span>
                  </div>
                </li>
              </div>
            </div>
            <div class="form-group col-12">
              <label>Nome da enquete</label>
              <div
                class="custom-input-icon-left form-control form-control-lg group_formcontrol"
              >
                <span class="browse-btn">@</span>
                <input class="" v-model="name" type="text" />
              </div>
            </div>

            <div class="form-group col-12 mb-1">
              <label>Opções</label>
            </div>
            <div class="col-12" v-for="option of options" :key="option.id">
              <div class="form-group col-12">
                <div
                  class="custom-input-icon-left form-control form-control-lg group_formcontrol"
                >
                  <span class="browse-btn">Aa</span>
                  <input
                    v-model="option.text"
                    @blur="addItemOption"
                    :placeholder="option.placeholder"
                    id="optionPoll"
                    name="optionPoll"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- Card -->
          <div
            class="form-row profile_form text-end float-end m-0 align-items-center"
          >
            <!-- Button -->
            <div class="cancel-btn me-4">
              <a
                href="javascript:void(0);"
                data-bs-dismiss="modal"
                aria-label="Close"
                >Cancelar</a
              >
            </div>
            <div class="">
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                :disabled="!isValidPoll()"
                v-on:click="sendPollMessage"
                class="btn btn-block newgroup_create mb-0"
              >
                Enviar enquete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Modal - Enviar enquete -->
</template>
