<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Message } from "../../../types/Message";
import { Contact } from "../../../types/Contact";
import { formatDateForChat } from "../../../helpers";

import ReplyMessage from "./ReplyMessageChatMessage.vue";
import MessageDeletedComponent from "./MessageDeletedComponent.vue";
import ChatBubbleInfo from "./ChatBubbleInfo.vue";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
    chat: {
      type: Object as PropType<Contact>,
      required: true,
    },
    showDeletedMessage: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    return { formatDateForChat };
  },
  components: { ReplyMessage, MessageDeletedComponent, ChatBubbleInfo },
  methods: {
    setDeletedMessageView(value: boolean) {
      this.$emit("setDeletedMessageView", value);
    },
    setReply(message: Message) {
      this.$emit("setReply", message);
    },
  },
});
</script>
<template>
  <div
    class="chat-content"
    style="min-width: 200px"
    @dblclick="setReply(message)"
  >
    <ReplyMessage :message="message" @click="$emit('getMessageReplyed')" />
    <MessageDeletedComponent
      :message="message"
      :showDeletedMessage="showDeletedMessage"
      @viewDeletedMessage="setDeletedMessageView(true)"
    />
    <div
      class="message-content"
      :id="'content-' + message.msgId"
      :class="{ 'd-none': message.ack === '-8' && !showDeletedMessage }"
    >
      <ChatBubbleInfo :message="message" />
      <div class="download-col">
        <ul>
          <li>
            <div class="image-download-col" v-if="message?.path">
              <a
                :href="
                  message.path?.includes('base64')
                    ? message.path
                    : 'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
                      message.path
                "
                data-fancybox="gallery"
                class="fancybox"
              >
                <img
                  v-lazy="{
                    src: message.path?.includes('base64')
                      ? message.path
                      : 'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
                        message.path,
                  }"
                  alt=""
                  style="width: 200px; height: 200px; object-fit: cover"
                />
              </a>
              <div class="download-action d-flex align-items-center">
                <div>
                  <a
                    :href="
                      'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
                      message.path
                    "
                    target="_blank"
                    download
                    ><i class="fas fa-cloud-download-alt"></i
                  ></a>
                </div>
                <div>
                  <a href="javascript:void(0);"
                    ><i class="fas fa-ellipsis-h"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="image-download-col" v-else>
              <div class="loader">
                <svg
                  version="1.1"
                  id="L9"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 100"
                  enable-background="new 0 0 0 0"
                  xml:space="preserve"
                >
                  <path
                    fill="#fff"
                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                  >
                    <animateTransform
                      attributeName="transform"
                      attributeType="XML"
                      type="rotate"
                      dur="1s"
                      from="0 50 50"
                      to="360 50 50"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              </div>
            </div>
            <div v-if="message.content">
              {{ message.content }}
            </div>
          </li>
        </ul>
      </div>
      <div class="chat-time">
        <div>
          <div class="time">
            <i class="fas fa-clock"></i>
            {{ formatDateForChat(message.timestamp) }}
          </div>
        </div>
      </div>
    </div>
    <div class="message-reaction" v-if="message.reactions">
      <h6>{{ message.reactions }}</h6>
    </div>
    <SenderNameChatMessage :message="message" :chat="chat" />
  </div>
</template>

<style>
.loader svg {
  width: 200px;
  height: 200px;
  display: inline-block;
}
</style>
