<script>
import { config } from "../../../public/config";
import userNoPhotoUrl from "../../../assets/img/profile-photo.jpg";
import logoTitanProfile from "../../../assets/img/logo.png";
import imgNuvem from "../../../assets/img/nuvem.png";
import mapsBackground from "../../../assets/img/maps-background.jpg";
import { useStore } from "../../../stores/dataStore";
import { api } from "../../../service/api";
import moment from "moment";
import { isMobile } from "../../../helpers/funcs";
import VueBasicAlert from "vue-basic-alert";
import ChatBubbleInfoVue from "./ChatBubbleInfo.vue";
import getNextMessage from "../../../helpers/messages/getNextMessage";

import {
  formatHtmlTextChat,
  formatDateForChat,
  convertByteToMegaByte,
} from "../../../helpers/";
import MessageDeletedComponent from "./MessageDeletedComponent.vue";
import LocationChatMessage from "./LocationChatMessage.vue";
import ContactChatMessage from "./ContactChatMessage.vue";
import PollChatMessage from "./PollChatMessage.vue";
import ImageChatMessage from "./ImageChatMessage.vue";
import VideoChatMessage from "./VideoChatMessage.vue";
import AudioChatMessage from "./AudioChatMessage.vue";
import SenderNameChatMessage from "./SenderNameChatMessage.vue";
import ReplyMessage from "./ReplyMessageChatMessage.vue";
import CallChatMessage from "./CallChatMessage.vue";
import Swal from "sweetalert2";
import { showAttributtesMessage } from "../../../helpers/messages/showAttributtesMessage";

const logoWhatsApp =
  "https://logodownload.org/wp-content/uploads/2015/04/whatsapp-logo-1-1.png";

export default {
  props: {
    msg_item: {
      type: Object,
      required: true,
    },
    chat_opened: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    forward_msgId: {
      type: Object,
      required: false,
    },
    isSearchModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    pause: {
      type: Boolean,
      required: false,
      defaultValue: false,
    },
    msgIdForAutoplay: {
      type: String,
      required: false,
    },
  },
  async mounted() {
    if (this.msg_item) {
      if (
        this.msg_item.type === "audio" ||
        this.msg_item.type === "ptt" ||
        this.msg_item.type === "sticker" ||
        this.msg_item.type === "document" ||
        this.msg_item.type === "image" ||
        this.msg_item.type === "video"
      ) {
        if (this.msg_item?.path?.includes("base64")) {
          this.urlAudio = this.msg_item.path;
        } else {
          await api
            .get(
              `${this.apiURL}static-files/public?id=${
                this.msg_item.path
              }&store=${
                this.msg_item.userIdSend === -1
                  ? "titanchat-retain"
                  : "titanchat-uploads"
              }&type=link`
            )
            .then((result) => {
              this.urlAudio = result.data;
            });
        }
      }
    }
  },
  components: {
    AudioChatMessage,
    MessageDeletedComponent,
    SenderNameChatMessage,
    ContactChatMessage,
    PollChatMessage,
    LocationChatMessage,
    ImageChatMessage,
    VueBasicAlert,
    ReplyMessage,
    CallChatMessage,
    ChatBubbleInfoVue,
    VideoChatMessage,
  },
  setup() {
    const data = useStore();

    return {
      data,
      formatHtmlTextChat,
      formatDateForChat,
      convertByteToMegaByte,
      isMobile,
      defaultPic: userNoPhotoUrl,
      getNextMessage,
      showAttributtesMessage,
    };
  },
  data(instance) {
    if (!instance) {
      return true;
    }
    return {
      apiURL: config.$api_url + "/",
      baseURL: config.$api_url + "/",
      urlAudio: "",
      showDeletedMessage: false,
    };
  },
  methods: {
    async transcribe(message) {
      try {
        const { data } = await api.post(`/ia/transcribe-ptt/${message.id}`);
        message.content = data;
      } catch (error) {
        console.error(error);
      }
    },
    async generateResponse(message) {
      const messages = [];
      let allMessages = this.chat_opened?.Messages.filter(
        (msg) => msg.type === "chat"
      );
      const index = allMessages.findIndex((msg) => msg.id === message.id);
      let startIndex = index - 6;
      startIndex = startIndex < 0 ? 0 : startIndex;

      if (startIndex === index) {
        messages.push({
          role: "user",
          content: message.content,
        });
      } else {
        allMessages = allMessages.slice(startIndex, index);
        for (const msg of allMessages) {
          if (
            msg.fromMe &&
            message.content != null &&
            message.content !== "" &&
            typeof message.content !== "symbol"
          ) {
            messages.push({
              role: "assistant",
              content: msg.content,
            });
          } else {
            if (
              message.content !== null &&
              message.content !== "" &&
              typeof message.content !== "symbol"
            ) {
              messages.push({
                role: "user",
                content: msg.content,
              });
            }
          }
        }
        messages.push({
          role: "user",
          content: message.content,
        });

        const input = document.getElementById("inputText");
        try {
          input.style.opacity = "0.5";
          input.style.pointerEvents = "none";
          const { data } = await api.post(`/ia/gen-reply-message`, {
            messages: messages,
          });
          if (data.length > 0) {
            input.value = data;
            //this.heightTextarea();
            input.classList.add("magictime", "puffIn");
            setTimeout(() => {
              input.classList.remove("magictime", "puffIn");
            }, 1000);
          }
          input.style.opacity = "1";
          input.style.pointerEvents = "auto";
        } catch (error) {
          input.style.opacity = "1";
          input.style.pointerEvents = "auto";
        }
      }
    },
    canEditable(message) {
      const now = moment();
      const end = moment(parseInt(message.timestamp) * 1000); // another date
      const duration = now.diff(end, "minutes");
      return (
        (duration < 15 && message.fromMe && message.type == "chat") ||
        message.dateScheduled
      );
    },
    canForward(message) {
      return (
        message.type !== "poll" &&
        !message.dateScheduled &&
        this.chat_opened.contact_type === "whatsapp"
      );
    },
    canReply(message) {
      return !message.dateScheduled;
    },
    canGenerateResponse(message) {
      return message.type === "chat" && !message.fromMe;
    },
    canTranscribe(message) {
      return (
        (message.type === "ptt" || message.type === "audio") && !message.content
      );
    },
    canCancelSchedule(message) {
      return message.isScheduled && message.dateScheduled;
    },
    canDeleteMessage(message) {
      return !message.dateScheduled && message.fromMe;
    },
    selectEditMessage(message) {
      this.$refs.alert.showAlert(
        "info", // There are 4 types of alert: success, info, warning, error
        "Digite o novo texto para a mensagem",
        "Dica",
        {
          iconSize: 35,
          iconType: "solid",
          position: "bottom right",
        }
      );
      const el = document.getElementById(message.id);
      el.focus();
      window.getSelection().selectAllChildren(el);
    },
    async editMessages(e) {
      const el = e.target;
      const { id, msgId, contactId, channelType, type } = el.attributes;
      const message = await this.data.findMessage(
        parseInt(contactId.value),
        msgId.value,
        id.value
      );
      message.isEdited = message.dateScheduled ? false : true;
      if (message.body === el.innerText) return;
      try {
        await api.put(`/message/${id.value}`, {
          text: el.innerText,
          channelType: channelType.value,
          type: type.value,
        });
        this.data.updateMessage(message.msgId, [
          { name: "body", value: el.innerText },
          { name: "content", value: el.innerText },
        ]);
        this.$refs.alertLess.showAlert("success", "Mensagem editada", "Ok", {
          iconSize: 35,
          iconType: "solid",
          position: "bottom right",
        });
      } catch (error) {
        el.innerText = message.body;
        message.isEdited = false;
        this.$refs.alert.showAlert(
          "error",
          "A mensagem não foi editada",
          "Ops, houve um erro",
          {
            iconSize: 35,
            iconType: "solid",
            position: "bottom right",
          }
        );
      }
    },
    setReply(msg_item) {
      const message = msg_item;
      const messageId = message.msgId ? message.msgId : message.id;
      message.msgId = messageId;

      if (message.ack == -8 || (message.userIdf && message.ack == 0))
        return true;
      const html = document.getElementById("content-" + messageId).innerHTML;
      this.$emit("setReply", { msg: message, html: html });
    },
    setForward(messages) {
      this.$emit("setForward", messages);
    },
    setPause(value) {
      this.$emit("setPause", value);
    },
    setdelMessage(chatId, msgId, chatType, contactId, store) {
      this.$emit("setdelMessage", chatId, msgId, chatType, contactId, store);
    },
    newContact(name, phone) {
      this.$emit("newContact", name, phone);
    },
    setReaction(msgId, chatId, contactId, key) {
      this.$emit("setReaction", msgId, chatId, contactId, key);
    },
    handleDropFile(ev) {
      this.$emit("handleDropFile", ev);
    },
    async closeAll() {
      //console.log("oie");
    },
    getMessageInChat(msgId, id) {
      this.$emit("clearSearchMessages");
      $(".close").trigger("click");
      this.$emit("getMessagesInChat", msgId, id);
    },
    getMessageReplyed() {
      if (this.isSearchModal) return;
      if (!this.msg_item.quotedMsgId) return;
      const element = document.getElementById(
        "content-" + this.msg_item.quotedMsgId
      );
      if (document.body.contains(element)) {
        element.animate(
          [
            { opacity: "0.4" },
            { transform: "translateY(0px)" },
            { transform: "translateY(-20px)" },
            { opacity: "1" },
          ],
          {
            duration: 300,
            iterations: 3,
          }
        );
        element.scrollIntoView({ block: "center", behavior: "instant" });
      } else {
        this.$emit("loadLastMessages");
        $(window).scrollTop(0);

        setTimeout(() => {
          this.getMessageReplyed();
        }, 300);
      }
    },
    showBtnReact(msgId, value) {
      const div = document.getElementById("reactBtn-" + msgId);
      if (value) {
        $(div).fadeIn("slow");
      } else {
        $(div).fadeOut("slow");
      }
      //document.getElementById('reactBtn-'+msgId).style.display  = value
    },
    async handleAddMsgToForward(e) {
      const value = e.target.checked;
      const id = e.target.id.replace("forward-", "");
      const message = await this.data.findMessage(this.chat_opened.id, id);
      if (value === true) {
        this.data.forward_msgIds.push(message.msgId);
        this.data.forward_messages.push(message.messageSocket);
      } else {
        this.data.forward_msgIds = this.data.forward_msgIds.filter(
          (msg) => msg !== id
        );
        this.data.forward_messages = this.data.forward_messages.filter(
          (msg) => msg !== message.messageSocket
        );
      }
    },
    markToForward() {
      this.data.modalForward = true;
      this.data.forward_msgIds = [];
      this.data.forward_chatsId = [];
      this.data.forward_messages = [];
    },
    async downloadMessage() {
      const channel_name =
        this.chat_opened.contact_type === "whatsapp" ? "Whatsapp" : "Telegram";
      document.getElementById(
        "percDown-" + this.msg_item.msgId
      ).innerHTML = `<span class="material-icons">file_download</span> Requisitando arquivo ao ${channel_name}...`;
      document.getElementById(
        "iconDown-" + this.msg_item.msgId
      ).innerHTML = `<i class="fas fa-spinner"></i>`;

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const { data } = await api.post(
        "message/download-message",
        {
          chatType: this.chat_opened.contact_type,
          chatId: this.chat_opened.id_api,
          msgId: this.msg_item.msgId,
          id: this.msg_item.id,
          fileId: this.msg_item.fileId,
          path: this.msg_item.path,
        },
        {
          onDownloadProgress: function (event) {
            const progress = Math.round((event.loaded * 100) / event.total);
            document.getElementById(
              "percDown-" + that.msg_item.msgId
            ).innerHTML = `<span class="material-icons">file_download</span> Baixando: ${progress} carregado...`;
          },
        }
      );
      if (data?.file) {
        const data2 = Uint8Array.from(data.file.data);
        const content = new Blob([data2], { type: data.mimetype });

        const encodedUri = window.URL.createObjectURL(content);
        const link = document.createElement("a");

        link.setAttribute("href", encodedUri);
        link.setAttribute("download", data.filename);
        link.click();
        link.remove();
        document.getElementById(
          "percDown-" + this.msg_item.msgId
        ).innerHTML = ``;
        document.getElementById(
          "iconDown-" + this.msg_item.msgId
        ).innerHTML = `<i class="fas fa-cloud-download-alt"></i>`;
      } else {
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("target", "_blank");
        link.click();
        link.remove();
        document.getElementById(
          "percDown-" + this.msg_item.msgId
        ).innerHTML = ``;
        document.getElementById(
          "iconDown-" + this.msg_item.msgId
        ).innerHTML = `<i class="fas fa-cloud-download-alt"></i>`;
      }
    },
    async cancelScheduledMessage(message) {
      try {
        await api.delete(`/message/${message.id}`);
      } catch (error) {
        Swal.error("Oops", "erro ao cancelar mensagem", "error");
      }
    },
    playNextAudio() {
      const nextmsg = this.getNextMessage(
        this.msg_item.msgId,
        this.chat_opened.Messages
      );
      if (nextmsg?.type === "audio" || nextmsg?.type === "ptt") {
        this.$emit("playAudioByMsgId", nextmsg.msgId);
      }
    },
  },
  computed: {
    profilePicUrl() {
      if (this.msg_item.userIdSend == -1) {
        return logoTitanProfile;
      } else if (this.msg_item.userIdSend == -3) {
        return imgNuvem;
      } else if (this.msg_item.userIdSend == -2) {
        return logoWhatsApp;
      } else if (this.msg_item.userIdSend) {
        if (this.msg_item.profilePicUrl) {
          return this.baseURL + this.msg_item.profilePicUrl;
        } else {
          return userNoPhotoUrl;
        }
      } else if (this.chat_opened.id_api.includes("@g.us")) {
        if (!this.msg_item.dataUserSended) return userNoPhotoUrl;
        if (JSON.parse(this.msg_item.dataUserSended).profilePicUrl) {
          return (
            this.baseURL +
            JSON.parse(this.msg_item.dataUserSended).profilePicUrl
          );
        } else {
          return userNoPhotoUrl;
        }
      } else if (!this.chat_opened.profilePicUrl) {
        return userNoPhotoUrl;
      } else {
        return this.chat_opened.profilePicUrl;
      }
    },
    getMapsIcon() {
      return mapsBackground;
    },
    getReactions() {
      const { contact_type } = this.chat_opened;
      if (
        contact_type !== "whatsapp" &&
        contact_type !== "instagram" &&
        contact_type !== "messenger"
      )
        return true;
      return this.msg_item.reactions;
    },
    dateFormatted() {
      return this.formatDateForChat(this.msg_item.timestamp);
    },
    headerDateMessage() {
      const lastIndex = this.index - 1;

      if (this.index == 0) {
        return formatt(this.msg_item?.timestamp);
      } else {
        if (
          isSameDate(
            this.chat_opened.Messages[lastIndex]?.timestamp,
            this.msg_item?.timestamp
          )
        ) {
          return "";
        } else {
          return formatt(this.msg_item?.timestamp);
        }
      }

      function formatt(timestamp) {
        const timestamp2 = moment.unix(timestamp);

        const REFERENCE = moment(); // fixed just for testing, use moment();
        const TODAY = REFERENCE.clone().startOf("day");
        const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
        const A_WEEK_OLD = REFERENCE.clone().subtract(2, "days").startOf("day");
        const A_YEAR_OLD = REFERENCE.clone()
          .subtract(364, "days")
          .startOf("day");

        if (timestamp2.isSame(TODAY, "d")) {
          return "HOJE";
        }
        if (timestamp2.isSame(YESTERDAY, "d")) {
          return "ONTEM";
        }
        if (timestamp2.isAfter(A_WEEK_OLD)) {
          return moment.unix(timestamp).format("DD/MM");
        }
        if (timestamp2.isAfter(A_YEAR_OLD)) {
          return moment.unix(timestamp).format("DD/MM/YYYY");
        }
      }
      function isSameDate(timestampanterior, timestampatual) {
        const timestamp2 = moment.unix(timestampanterior);
        const REFERENCE = moment.unix(timestampatual);
        const TODAY = REFERENCE.clone().startOf("day");

        if (timestamp2.isSame(TODAY, "d")) {
          return true;
        } else {
          return false;
        }
      }
    },
    formatImageSrc() {
      if (this.msg_item?.path?.includes("base64")) {
        return this.msg_item.path;
      } else {
        return `${this.apiURL}static-files/public?id=${
          this.msg_item.path
          // eslint-disable-next-line vue/no-use-computed-property-like-method
        }&store=${
          this.msg_item.userIdSend === -1
            ? "titanchat-retain"
            : "titanchat-uploads"
        }`;
      }
    },
    getMessageId() {
      return this.msg_item.msgId;
    },
    getButtonsList() {
      let buttons = this.msg_item.buttons;
      if (!buttons) {
        return "";
      }
      if (!Array.isArray(buttons)) {
        buttons = JSON.parse(buttons);
      }
      buttons.map((btt) => {
        if (!btt.displayText) {
          btt.displayText = btt.text;
        }
      });
      return buttons;
    },
  },
};
</script>
<template>
  <vue-basic-alert :duration="300" :closeIn="4000" ref="alert" />
  <vue-basic-alert :duration="100" :closeIn="1000" ref="alertLess" />
  <div
    class="messages"
    :class="{ 'mb-1': data.userisMobile }"
    :id="'message-' + msg_item?.id"
    v-if="msg_item?.id"
    @mouseover="showBtnReact(getMessageId, true)"
    @mouseleave="showBtnReact(getMessageId, false)"
    v-on:drop="handleDropFile"
  >
    <div class="chat-line" v-if="headerDateMessage">
      <span class="chat-date">{{ headerDateMessage }}</span>
    </div>

    <div
      class="chat-line"
      v-if="msg_item.type == 'reaction' && !msg_item.fromMe"
    >
      <span class="chat-date"
        >{{ chat_opened.name }} reagiu à "{{
          msg_item.quotedMsg?.content?.toString()?.substring(0, 26)
        }}
        {{ msg_item.quotedMsg?.content?.toString().length > 26 ? "..." : "" }}"
        com "{{ msg_item.body }}"</span
      >
    </div>
    <div class="chat-line" v-if="msg_item.type == 'chat-finished'">
      <span class="chat-date" style="background-color: #40944e"
        >Conversa finalizada pelo usuário
        {{ data.getUserById(msg_item?.userIdSend)?.name }}</span
      >
    </div>

    <div
      class="chat-line calllog"
      v-if="msg_item.type == 'gp2' && msg_item.subtype == 'leave'"
    >
      <span class="chat-date" style="background-color: #9e3929"
        ><span class="material-icons">close</span>
        <span :id="'leave-' + getMessageId"
          >Uma pessoa acabou de sair do grupo</span
        ></span
      >
    </div>
    <div
      class="chats"
      :style="{ cursor: isSearchModal ? 'pointer' : 'auto' }"
      v-if="msg_item.msgId || msg_item.id"
      @click="
        isSearchModal ? getMessageInChat(msg_item.msgId, msg_item.id) : null
      "
      v-bind:class="{ 'chats-right': msg_item.userIdSend }"
    >
      <span
        v-if="
          !(msg_item.type == 'call_log') &&
          !(msg_item.type == 'gp2') &&
          !(msg_item.type == 'ptt') &&
          !(msg_item.type == 'audio') &&
          !(msg_item.type == 'reaction') &&
          !(msg_item.type == 'chat-finished') &&
          !(msg_item.type == 'call') &&
          !data.userisMobile
        "
      >
        <div class="chat-avatar">
          <img
            v-lazy="{ src: profilePicUrl, error: defaultPic }"
            class="rounded-circle dreams_chat"
            alt="image"
            :style="
              'opacity:' +
              (showAttributtesMessage(
                msg_item,
                chat_opened.Messages,
                data.user.id
              )
                ? '1'
                : '0')
            "
          />
        </div>
      </span>

      <div class="chat-content" v-if="msg_item.type == 'nomessage'">
        <div class="message-content">
          <div r v-html="formatHtmlTextChat(msg_item.content)"></div>
        </div>
        <div
          class="message-reaction"
          v-if="msg_item.reactions && chat_opened.contact_type === 'whatsapp'"
        >
          <h6>{{ getReactions }}</h6>
        </div>
        <div class="chat-profile-name" v-show="!isMobile">
          <h6>Titan Chat</h6>
        </div>
      </div>
      <ContactChatMessage
        v-if="msg_item.type == 'vcard'"
        :message="msg_item"
        :chat="chat_opened"
        @setReply="setReply"
        @getMessageReplyed="getMessageReplyed"
        @setDeletedMessageView="(value) => (showDeletedMessage = value)"
      />
      <PollChatMessage
        v-if="msg_item.type == 'poll'"
        :message="msg_item"
        :chat="chat_opened"
        @setReply="setReply"
        @setDeletedMessageView="(value) => (showDeletedMessage = value)"
      />
      <div
        class="chat-content"
        @dblclick="setReply(msg_item)"
        v-if="msg_item.type == 'document'"
      >
        <div
          class="reply-content"
          v-on:click="getMessageReplyed"
          v-if="msg_item.quotedMsgId"
          :id="'reply-' + msg_item.quotedMsgId"
        >
          {{ formatReplyMessage }}
        </div>
        <MessageDeletedComponent
          :message="msg_item"
          :showDeletedMessage="showDeletedMessage"
          @viewDeletedMessage="showDeletedMessage = true"
        />
        <div
          class="message-content"
          :id="'content-' + getMessageId + (isSearchModal ? 'search' : '')"
          :class="{ 'd-none': msg_item.ack === '-8' && !showDeletedMessage }"
        >
          <ChatBubbleInfoVue :message="msg_item" />
          <div class="file-download d-flex align-items-center">
            <div
              class="file-type d-flex align-items-center justify-content-center me-2"
            >
              <i class="far fa-file-archive"></i>
            </div>
            <div class="file-details">
              <span class="file-name">{{ msg_item.filename }}</span>
              <span class="file-size">{{
                convertByteToMegaByte(parseInt(msg_item.size))
              }}</span>
            </div>
            <div class="download-action d-flex align-items-center">
              <div>
                <a
                  @click="downloadMessage"
                  :id="'iconDown-' + msg_item.msgId"
                  style="cursor: pointer"
                  ><i class="fas fa-cloud-download-alt"></i
                ></a>
              </div>
              <div>
                <a href="javascript:void(0);"
                  ><i class="fas fa-ellipsis-h"></i
                ></a>
              </div>
            </div>
          </div>
          <div
            :contenteditable="canEditable(msg_item)"
            :id="msg_item.id"
            :contactId="msg_item.contactId"
            :msgId="msg_item.msgId"
            :channelType="chat_opened.contact_type"
            type="document"
            spellcheck="false"
            @blur="editMessages"
            v-html="formatHtmlTextChat(msg_item.content)"
          ></div>
          <div class="chat-time">
            <div>
              <div class="time">
                <i class="fas fa-clock"></i> {{ dateFormatted }}
              </div>
              <div class="time" :id="'percDown-' + msg_item.msgId"></div>
            </div>
          </div>
        </div>
        <div
          class="message-reaction"
          v-if="msg_item.reactions && chat_opened.contact_type === 'whatsapp'"
        >
          <h6>{{ getReactions }}</h6>
        </div>
        <SenderNameChatMessage :message="msg_item" :chat="chat_opened" />
      </div>
      <AudioChatMessage
        v-if="msg_item.type == 'ptt' || msg_item.type == 'audio'"
        :message="msg_item"
        :chat="chat_opened"
        :pic="profilePicUrl"
        :pause="pause"
        :msgIdForAutoplay="msgIdForAutoplay"
        @setPause="setPause"
        @playNextAudio="playNextAudio"
        @setReply="setReply"
        @getMessageReplyed="getMessageReplyed"
        @setDeletedMessageView="(value) => (showDeletedMessage = value)"
      />
      <div
        class="chat-content"
        @dblclick="setReply(msg_item)"
        v-if="msg_item.type == 'ciphertext'"
      >
        <div
          class="reply-content"
          v-on:click="getMessageReplyed"
          v-if="msg_item.quotedMsgId"
          :id="'reply-' + msg_item.quotedMsgId"
        >
          {{ formatReplyMessage }}
        </div>
        <MessageDeletedComponent
          :message="msg_item"
          :showDeletedMessage="showDeletedMessage"
          @viewDeletedMessage="showDeletedMessage = true"
        />
        <div
          class="message-content"
          :id="'content-' + getMessageId + (isSearchModal ? 'search' : '')"
          :class="{ 'd-none': msg_item.ack === '-8' && !showDeletedMessage }"
        >
          <template v-if="msg_item.isForwarded">
            <small style="font-size: 7pt"
              ><span class="material-icons">redo</span>
              <i>Encaminhada</i></small
            >
          </template>
          <div
            r
            v-html="
              formatHtmlTextChat(
                '<i>Mensagem encriptada. Peça ao cliente, para reenviar</i>'
              )
            "
          ></div>
          <div class="chat-time">
            <div>
              <div class="time">
                <i class="fas fa-clock"></i> {{ dateFormatted }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="message-reaction"
          v-if="msg_item.reactions && chat_opened.contact_type === 'whatsapp'"
        >
          <h6>{{ getReactions }}</h6>
        </div>
        <SenderNameChatMessage :message="msg_item" :chat="chat_opened" />
      </div>
      <div
        class="chat-content"
        @dblclick="setReply(msg_item)"
        v-if="msg_item.type == 'chat'"
      >
        <ReplyMessage :message="msg_item" @click="getMessageReplyed" />
        <MessageDeletedComponent
          :message="msg_item"
          :showDeletedMessage="showDeletedMessage"
          @viewDeletedMessage="showDeletedMessage = true"
        />
        <div
          class="message-content"
          :class="{ 'd-none': msg_item.ack === '-8' && !showDeletedMessage }"
          :id="'content-' + getMessageId + (isSearchModal ? 'search' : '')"
        >
          <ChatBubbleInfoVue :message="msg_item" />
          <div
            r
            v-html="formatHtmlTextChat(msg_item.content)"
            @blur="editMessages"
            :id="msg_item.id"
            :contactId="msg_item.contactId"
            :msgId="msg_item.msgId"
            :channelType="chat_opened.contact_type"
            spellcheck="false"
            type="chat"
            :contenteditable="canEditable(msg_item)"
          ></div>
          <div class="chat-time">
            <div>
              <div class="time">
                <i class="fas fa-clock"></i> {{ dateFormatted }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="message-reaction"
          v-if="msg_item.reactions && chat_opened.contact_type === 'whatsapp'"
        >
          <h6>{{ getReactions }}</h6>
        </div>
        <SenderNameChatMessage :message="msg_item" :chat="chat_opened" />
      </div>
      <div
        class="chat-content"
        @dblclick="setReply(msg_item)"
        v-if="msg_item.type == 'chat_buttons'"
      >
        <ReplyMessage :message="msg_item" @click="getMessageReplyed" />
        <MessageDeletedComponent
          :message="msg_item"
          :showDeletedMessage="showDeletedMessage"
          @viewDeletedMessage="showDeletedMessage = true"
        />
        <div
          class="message-content"
          :id="'content-' + getMessageId + (isSearchModal ? 'search' : '')"
          :class="{ 'd-none': msg_item.ack === '-8' && !showDeletedMessage }"
        >
          <template v-if="msg_item.isForwarded">
            <small style="font-size: 7pt"
              ><span class="material-icons">redo</span>
              <i>Encaminhada</i></small
            >
          </template>
          <b><div r v-html="formatHtmlTextChat(msg_item.title)"></div></b>
          <div r v-html="formatHtmlTextChat(msg_item.content)"></div>
          <div
            r
            style="font-size: 9px; opacity: 0.7"
            v-html="formatHtmlTextChat(msg_item.footer)"
          ></div>
          <div class="chat-time">
            <div>
              <div class="time">
                <i class="fas fa-clock"></i> {{ dateFormatted }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="message-reaction"
          v-if="msg_item.reactions && chat_opened.contact_type === 'whatsapp'"
        >
          <h6>{{ getReactions }}</h6>
        </div>
        <div
          class="chat-profile-name"
          style="text-align: center; align-items: center"
          v-for="btt in getButtonsList"
          v-bind:key="btt.id"
        >
          <button
            type="buttom"
            style="
              text-align: center;
              opacity: 0.5;
              pointer-events: none;
              margin-top: 5px;
            "
            class="btn btn-block newgroup_create mb-0"
          >
            {{ btt.displayText }}
          </button>
        </div>
        <SenderNameChatMessage :message="msg_item" :chat="chat_opened" />
      </div>

      <div
        class="chat-content"
        @dblclick="setReply(msg_item)"
        v-if="msg_item.type == 'template_button_reply'"
      >
        <ReplyMessage :message="msg_item" @click="getMessageReplyed" />
        <MessageDeletedComponent
          :message="msg_item"
          :showDeletedMessage="showDeletedMessage"
          @viewDeletedMessage="showDeletedMessage = true"
        />
        <div
          class="message-content"
          :id="'content-' + getMessageId + (isSearchModal ? 'search' : '')"
          :class="{ 'd-none': msg_item.ack === '-8' && !showDeletedMessage }"
        >
          <template v-if="msg_item.isForwarded">
            <small style="font-size: 7pt"
              ><span class="material-icons">redo</span>
              <i>Encaminhada</i></small
            >
          </template>
          <div r v-html="formatHtmlTextChat(msg_item.content)"></div>
          <div class="chat-time">
            <div>
              <div class="time">
                <i class="fas fa-clock"></i> {{ dateFormatted }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="message-reaction"
          v-if="msg_item.reactions && chat_opened.contact_type === 'whatsapp'"
        >
          <h6>{{ getReactions }}</h6>
        </div>
        <SenderNameChatMessage :message="msg_item" :chat="chat_opened" />
      </div>
      <div
        class="chat-content"
        @dblclick="setReply(msg_item)"
        v-if="msg_item.type == 'list'"
      >
        <ReplyMessage :message="msg_item" @click="getMessageReplyed" />
        <MessageDeletedComponent
          :message="msg_item"
          :showDeletedMessage="showDeletedMessage"
          @viewDeletedMessage="showDeletedMessage = true"
        />
        <div
          class="message-content"
          :id="'content-' + getMessageId + (isSearchModal ? 'search' : '')"
          :class="{ 'd-none': msg_item.ack === '-8' && !showDeletedMessage }"
        >
          <template v-if="msg_item.isForwarded">
            <small style="font-size: 7pt"
              ><span class="material-icons">redo</span>
              <i>Encaminhada</i></small
            >
          </template>
          <div r v-html="formatHtmlTextChat(msg_item.content)"></div>
          <div class="chat-time">
            <div>
              <div class="time">
                <i class="fas fa-clock"></i> {{ dateFormatted }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="message-reaction"
          v-if="msg_item.reactions && chat_opened.contact_type === 'whatsapp'"
        >
          <h6>{{ getReactions }}</h6>
        </div>
        <div
          class="chat-profile-name"
          style="text-align: center; align-items: center"
        >
          <button
            type="buttom"
            style="
              text-align: center;
              opacity: 0.5;
              pointer-events: none;
              margin-top: 5px;
            "
            class="btn btn-block newgroup_create mb-0"
          >
            {{ msg_item.buttonText }}
          </button>
        </div>
        <SenderNameChatMessage :message="msg_item" :chat="chat_opened" />
      </div>

      <LocationChatMessage
        v-if="msg_item.type == 'location'"
        :message="msg_item"
        :chat="chat_opened"
        @setReply="setReply"
        @getMessageReplyed="getMessageReplyed"
        @setDeletedMessageView="(value) => (showDeletedMessage = value)"
      />
      <CallChatMessage
        v-if="msg_item.type == 'call'"
        :message="msg_item"
        :chat="chat_opened"
      />

      <ImageChatMessage
        v-if="msg_item.type == 'image'"
        :message="msg_item"
        :chat="chat_opened"
        :canEditable="canEditable"
        @editMessages="editMessages"
        @setReply="setReply"
        @getMessageReplyed="getMessageReplyed"
        @setDeletedMessageView="(value) => (showDeletedMessage = value)"
      />
      <VideoChatMessage
        v-if="msg_item.type == 'video'"
        :message="msg_item"
        :chat="chat_opened"
        :canEditable="canEditable"
        @editMessages="editMessages"
        @setReply="setReply"
        @getMessageReplyed="getMessageReplyed"
        @setDeletedMessageView="(value) => (showDeletedMessage = value)"
      />

      <div
        class="chat-content"
        @dblclick="setReply(msg_item)"
        v-if="msg_item.type == 'sticker'"
      >
        <ReplyMessage :message="msg_item" @click="getMessageReplyed" />
        <MessageDeletedComponent
          :message="msg_item"
          :showDeletedMessage="showDeletedMessage"
          @viewDeletedMessage="showDeletedMessage = true"
        />
        <img
          v-lazy="{ src: formatImageSrc }"
          alt="Sticker"
          style="max-width: 120px"
        />
        <div
          class="message-content"
          style="background-color: transparent"
          :id="'content-' + getMessageId + (isSearchModal ? 'search' : '')"
          :class="{ 'd-none': msg_item.ack === '-8' && !showDeletedMessage }"
        >
          <div class="chat-time">
            <div>
              <div class="time">
                <i class="fas fa-clock"></i> {{ dateFormatted }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="message-reaction"
          v-if="msg_item.reactions && chat_opened.contact_type === 'whatsapp'"
        >
          <h6>{{ getReactions }}</h6>
        </div>
      </div>

      <div class="chat-action-btns ms-3">
        <div
          class="chat-avatar"
          v-if="
            msg_item.ack != -8 &&
            msg_item.userIdSend != -1 &&
            msg_item.type != 'call_log' &&
            this.data.modalForward === true
          "
        >
          <div class="select-forward">
            <input
              type="checkbox"
              :id="'forward-' + getMessageId"
              v-on:change="handleAddMsgToForward"
            />
          </div>
        </div>
        <div
          class="chat-action-col"
          v-if="
            msg_item.ack != -8 &&
            !(
              msg_item.type == 'call' ||
              msg_item.type == 'gp2' ||
              msg_item.type == 'reaction' ||
              msg_item.type == 'chat-finished'
            )
          "
        >
          <a class="#" href="#" data-bs-toggle="dropdown">
            <i class="fas fa-ellipsis-h"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <a
              href="javascript:void(0);"
              class="dropdown-item dream_profile_menu"
              @click="setReply(msg_item)"
              v-show="canReply(msg_item)"
              >Responder <span><i class="far fa-copy"></i></span
            ></a>
            <a
              href="javascript:void(0);"
              class="dropdown-item dream_profile_menu"
              @click="generateResponse(msg_item)"
              v-show="canGenerateResponse(msg_item)"
              >Gerar resposta com IA <span><i class="fas fa-robot"></i></span
            ></a>
            <a
              href="javascript:void(0);"
              class="dropdown-item dream_profile_menu"
              @click="transcribe(msg_item)"
              v-show="canTranscribe(msg_item)"
              >Transcrever <span><i class="fas fa-font"></i></span
            ></a>
            <a
              href="javascript:void(0);"
              class="dropdown-item"
              v-show="canForward(msg_item)"
              :id="'btnforward-' + getMessageId"
              @click="markToForward"
              >Encaminhar <span><i class="fas fa-share"></i></span
            ></a>
            <a
              href="javascript:void(0);"
              class="dropdown-item"
              v-show="canEditable(msg_item)"
              @click="selectEditMessage(msg_item)"
              >Editar <span><i class="fas fa-edit"></i></span
            ></a>
            <a
              v-if="msg_item.userIdSend"
              href="javascript:void(0);"
              class="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#del-mensagem"
              data-bs-dismiss="modal"
              aria-label="Close"
              v-show="
                canDeleteMessage(msg_item) && data.hasPermission('delete-msgs')
              "
              @click="
                setdelMessage(
                  msg_item.chatId,
                  msg_item.msgId,
                  chat_opened.contact_type,
                  chat_opened.id,
                  msg_item
                )
              "
              >Deletar <span><i class="far fa-trash-alt"></i></span
            ></a>

            <a
              v-if="msg_item.userIdSend"
              href="javascript:void(0);"
              class="dropdown-item"
              v-show="canCancelSchedule(msg_item)"
              @click="cancelScheduledMessage(msg_item)"
              >Cancelar <span><i class="far fa-trash-alt"></i></span
            ></a>
          </div>
        </div>
        <template
          v-if="
            msg_item.type !== 'reaction' &&
            msg_item.type !== 'chat-finished' &&
            msg_item.type !== 'audio' &&
            msg_item.type !== 'ptt'
          "
        >
          <div
            class="chat-read-col"
            style="color: #eb4034; cursor: pointer"
            v-if="msg_item.ack == -1 && msg_item.userIdSend"
          >
            <span class="material-icons" title="Falha no envio da mensagem..."
              >error</span
            >
          </div>
          <div
            class="chat-read-col"
            style="color: #13af28; cursor: pointer"
            v-if="msg_item.ack == 9 && msg_item.userIdSend"
          >
            <span class="material-icons" title="Mensagem agendada">update</span>
          </div>
          <div
            class="chat-read-col"
            style="color: #46484d"
            v-else-if="msg_item.ack == 0 && msg_item.userIdSend"
          >
            <span class="material-icons">update</span>
          </div>
          <div
            class="chat-read-col"
            style="color: #46484d"
            v-else-if="msg_item.ack == 1 && msg_item.userIdSend"
          >
            <span class="material-icons">done</span>
          </div>
          <div
            class="chat-read-col"
            style="color: #46484d"
            v-else-if="msg_item.ack == 2 && msg_item.userIdSend"
          >
            <span class="material-icons">done_all</span>
          </div>
          <div
            class="chat-read-col"
            style=""
            v-else-if="msg_item.ack == 3 && msg_item.userIdSend"
          >
            <span class="material-icons">done_all</span>
          </div>
          <div
            class="chat-read-col"
            style=""
            v-else-if="msg_item.ack == 4 && msg_item.userIdSend"
          >
            <span class="material-icons">volume_up</span>
          </div></template
        >
      </div>
      <div
        class="chat-action-btns ms-3"
        v-if="
          chat_opened.contact_type === 'whatsapp' &&
          msg_item.ack != -8 &&
          msg_item.userIdSend != -1 &&
          !(
            msg_item.type == 'call_log' ||
            msg_item.type == 'gp2' ||
            msg_item.type == 'reaction' ||
            msg_item.type == 'chat-finished'
          ) &&
          !Array.isArray(data.forward_msgId)
        "
      >
        <div
          class="chat-reaction-buttom"
          v-on:click="
            setReaction(
              getMessageId,
              msg_item.chatId,
              msg_item.contactId,
              msg_item.messageSocket.key
            )
          "
          data-bs-toggle="modal"
          data-bs-target="#reaction-message"
          data-bs-dismiss="modal"
          aria-label="Close"
          :id="'reactBtn-' + getMessageId"
        >
          <span
            style="
              filter: gray;
              -webkit-filter: grayscale(1);
              filter: grayscale(1);
            "
            >😯</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.dropdown-item.disabled {
  opacity: 0.5;
}
.darkmode
  .chat
  .chat-body
  .messages
  .chats.chats-right
  .message-content
  .chat-time
  .time
  i {
  color: #8d8c8e;
}
</style>
