<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Message } from "../../../types/Message";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
  },
});
</script>
<template>
  <div>
    <template v-if="message.isForwarded">
      <small style="font-size: 7pt"
        ><span class="material-icons">redo</span> <i>Encaminhada</i></small
      >
    </template>
    <template v-if="message.isEdited">
      <small style="font-size: 7pt"
        ><span class="material-icons">edit</span> <i>Editada</i></small
      >
    </template>
    <template v-if="message.isBroadcast">
      <small style="font-size: 7pt"
        ><span class="material-icons">fast_forward</span>
        <i>Lista de transmissão</i></small
      >
    </template>
    <template v-if="message.isScheduled">
      <small style="font-size: 7pt"
        ><span class="material-icons">schedule</span> <i>Agendada</i></small
      >
    </template>
  </div>
</template>
