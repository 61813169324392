<script lang="ts">
import { PropType, defineComponent } from "vue";
import { isMobile } from "../../../helpers/funcs";
import { useStore } from "../../../stores/dataStore";
import { ChatCollection } from "../../../stores/chatsStore";
import { Message } from "../../../types/Message";
import { getPreviousMessage } from "../../../helpers/messages/getPreviousMessage";
import { showAttributtesMessage } from "../../../helpers/messages/showAttributtesMessage";
import getNextMessage from "../../../helpers/messages/getNextMessage";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
    chat: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      isMobile,
      User: () => useStore(),
      getPreviousMessage,
      ChatCollection,
      getNextMessage,
      showAttributtesMessage,
    };
  },
  computed: {
    getSenderName() {
      if (this.message.userIdSend == -1) {
        return "Titan Chat Bot";
      } else if (this.message.userIdSend == -2) {
        return "WhatsApp";
      } else if (this.message.userIdSend == -3) {
        return "API";
      } else if (this.message.userIdSend) {
        return this.User()?.getUserById(this.message.userIdSend)?.name || "";
      } else if (this.chat?.id_api?.includes("@g.us")) {
        return `~ ${this.message?.author || "Desconhecido"}`;
      } else {
        return this.chat?.name || "";
      }
    },
  },
});
</script>
<template>
  <div
    class="chat-profile-name"
    v-show="showAttributtesMessage(message, chat.Messages, User().user.id)"
  >
    <h6>{{ getSenderName }}</h6>
  </div>
</template>
