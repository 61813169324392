<template>
  <div class="rolldown">
    <div
      role="button"
      tabindex="0"
      aria-label="Deslizar para o fim da página"
      class="sub"
      data-tab="7"
      style="transform: scaleX(1) scaleY(1); opacity: 1"
    >
      <span class="SruXD"></span
      ><span data-icon="down" class="_28HTg"
        ><svg
          viewBox="0 0 19 20"
          height="20"
          width="19"
          preserveAspectRatio="xMidYMid meet"
          class=""
          version="1.1"
          x="0px"
          y="0px"
        >
          <path
            fill="currentColor"
            d="M3.8,6.7l5.7,5.7l5.7-5.7l1.6,1.6l-7.3,7.2L2.2,8.3L3.8,6.7z"
          ></path></svg
      ></span>
    </div>
  </div>
</template>
<style>
.rolldown {
  position: absolute;
  bottom: 50px;
  right: 40px;
  z-index: 200;
  line-height: 29pt;
}
</style>
