// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dropdown-item.disabled {
  opacity: 0.5;
}
.darkmode
  .chat
  .chat-body
  .messages
  .chats.chats-right
  .message-content
  .chat-time
  .time
  i {
  color: #8d8c8e;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
