import { createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "download-col"
}
const _hoisted_3 = { class: "image-download-col" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { style: {"width":"100px","height":"100px","object-fit":"cover","border-radius":"15px","opacity":"0.7"} }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" @click=\"getMessageReplyed\" "),
    (_ctx.message.quotedMsgId)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "reply-content",
          id: 'reply-' + _ctx.message.quotedMsgId
        }, [
          (_ctx.message?.quotedMsg?.type == 'image')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("a", {
                        href: 
                _ctx.message.quotedMsg.path?.includes('base64')
                  ? _ctx.message.quotedMsg.path
                  : 'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
                    _ctx.message.quotedMsg.path
              ,
                        "data-fancybox": "gallery",
                        class: "fancybox"
                      }, [
                        _withDirectives(_createElementVNode("img", _hoisted_5, null, 512 /* NEED_PATCH */), [
                          [_directive_lazy, {
                  src: _ctx.message.quotedMsg.path?.includes('base64')
                    ? _ctx.message.quotedMsg.path
                    : 'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
                      _ctx.message.quotedMsg.path,
                }]
                        ])
                      ], 8 /* PROPS */, _hoisted_4)
                    ]),
                    (_ctx.message.quotedMsg?.content)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.message.quotedMsg.content), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true)
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.formatReplyMessage), 1 /* TEXT */))
        ], 8 /* PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}