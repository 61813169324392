<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Message } from "../../../types/Message";
import { Contact } from "../../../types/Contact";
import { formatDateForChat } from "../../../helpers";

import ReplyMessage from "./ReplyMessageChatMessage.vue";
import MessageDeletedComponent from "./MessageDeletedComponent.vue";
import SenderNameChatMessage from "./SenderNameChatMessage.vue";
import ChatBubbleInfo from "./ChatBubbleInfo.vue";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
    chat: {
      type: Object as PropType<Contact>,
      required: true,
    },
    showDeletedMessage: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    return { formatDateForChat };
  },
  components: {
    ReplyMessage,
    MessageDeletedComponent,
    SenderNameChatMessage,
    ChatBubbleInfo,
  },
  methods: {
    setDeletedMessageView(value: boolean) {
      this.$emit("setDeletedMessageView", value);
    },
    setReply(message: Message) {
      this.$emit("setReply", message);
    },
  },
});
</script>
<template>
  <div class="chat-content" @dblclick="setReply(message)">
    <ReplyMessage :message="message" @click="$emit('getMessageReplyed')" />
    <MessageDeletedComponent
      :message="message"
      :showDeletedMessage="showDeletedMessage"
      @viewDeletedMessage="setDeletedMessageView(true)"
    />
    <div
      class="message-content"
      :id="'content-' + message.msgId"
      :class="{ 'd-none': message.ack === '-8' && !showDeletedMessage }"
    >
      <ChatBubbleInfo :message="message" />
      <div class="file-download d-flex align-items-center">
        <div
          class="file-type d-flex align-items-center justify-content-center me-2"
        >
          <i class="fas fa-user-astronaut"></i>
        </div>
        <div class="file-details">
          <span class="file-name">{{ message.contactsMessage?.name }}</span>
          <span
            class="file-size"
            v-for="number of message.contactsMessage?.contacts"
            :key="number"
            >{{ number }}</span
          >
          <!--<a
                href="javascript:void(0);"
                v-on:click="newContact(vcardName, vcardPhone)"
                >Salvar contato</a
              >-->
        </div>
      </div>
      <div class="chat-time">
        <div>
          <div class="time">
            <i class="fas fa-clock"></i>
            {{ formatDateForChat(message.timestamp) }}
          </div>
        </div>
      </div>
    </div>
    <div class="message-reaction" v-if="message.reactions">
      <h6>{{ message.reactions }}</h6>
    </div>
    <SenderNameChatMessage :message="message" :chat="chat" />
  </div>
</template>
