<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Message } from "../../../types/Message";
import { Contact } from "../../../types/Contact";
import { formatDateForChat } from "../../../helpers";

import ReplyMessage from "./ReplyMessageChatMessage.vue";
import MessageDeletedComponent from "./MessageDeletedComponent.vue";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
    chat: {
      type: Object as PropType<Contact>,
      required: true,
    },
    showDeletedMessage: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    return { formatDateForChat };
  },
  components: { ReplyMessage, MessageDeletedComponent },
  methods: {
    setDeletedMessageView(value: boolean) {
      this.$emit("setDeletedMessageView", value);
    },
    setReply(message: Message) {
      this.$emit("setReply", message);
    },
  },
});
</script>
<template>
  <div
    class="chat-content"
    style="min-width: 200px"
    @dblclick="setReply(message)"
  >
    <ReplyMessage :message="message" />
    <MessageDeletedComponent
      :message="message"
      :showDeletedMessage="showDeletedMessage"
      @viewDeletedMessage="setDeletedMessageView(true)"
    />
    <div
      class="message-content"
      :id="'content-' + message.msgId"
      :class="{ 'd-none': message.ack === '-8' && !showDeletedMessage }"
    >
      <p style="font-size: 12pt">{{ message.title || message.body }}</p>
      <div class="col-12">
        <div
          class="settings-control"
          style="border: 0"
          v-for="option of message.list_sections"
          :key="option"
        >
          <li class="d-flex align-items-center">
            <label class="switch me-3">
              <input type="checkbox" :id="option" />
              <span class="slider round"></span>
            </label>
            <div>
              <span>{{ (option as any)?.optionName || option }}</span>
            </div>
          </li>
        </div>
      </div>
      <span class="text-muted"
        >Ainda não é possível responder enquetes, função à ser
        implementada</span
      >
      <div class="chat-time">
        <div>
          <div class="time">
            <i class="fas fa-clock"></i>
            {{ formatDateForChat(message.timestamp) }}
          </div>
        </div>
      </div>
    </div>
    <div class="message-reaction" v-if="message.reactions">
      <h6>{{ message.reactions }}</h6>
    </div>
    <SenderNameChatMessage :message="message" :chat="chat" />
  </div>
</template>
