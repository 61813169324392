import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chat-profile-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", null, _toDisplayString(_ctx.getSenderName), 1 /* TEXT */)
  ], 512 /* NEED_PATCH */)), [
    [_vShow, _ctx.showAttributtesMessage(_ctx.message, _ctx.chat.Messages, _ctx.User().user.id)]
  ])
}