<script lang="ts">
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { useStore } from "../../stores/dataStore";
import { api } from "../../service/api";
import { PropType, defineComponent } from "vue";
import { Contact } from "../../types/Contact";
import Swal from "sweetalert2";
import ChatMessage from "./Messages/ChatMessage.vue";
import { Message } from "../../types/Message";

export default defineComponent({
  props: {
    chat: {
      type: Object as PropType<Contact>,
      required: true,
    },
  },
  setup() {
    const data = useStore();
    return { data };
  },
  data() {
    return {
      searchQuery: "",
      isSearching: false,
      isTyping: true,
      timeTyping: null,
      items: [] as unknown as Message[],
    };
  },
  components: { PerfectScrollbar, ChatMessage },
  methods: {
    clearSearch() {
      $("#search-new-chat").val("");
      this.items = [];
    },
    async inputQuery() {
      this.isSearching = true;
      clearTimeout(this.timeTyping as any);
      this.timeTyping = setTimeout(async () => {
        this.find();
      }, 500) as any;
    },

    async find() {
      if (this.searchQuery == "") {
        this.isSearching = false;
        this.items = [];
        return;
      }
      try {
        const { data } = await api.get(`/messages/find`, {
          params: {
            query: this.searchQuery,
            contactId: this.chat.id,
          },
        });
        this.items = data;
        this.isSearching = false;
      } catch (error) {
        Swal.fire("Oops!", "Houve um erro ao buscar mensagens...");
      }
    },
    loadLastMessages() {
      this.$emit("loadLastMessages");
    },
    getMessagesInChat(msgId: string, id: number) {
      this.$emit("getMessagesInChat", msgId, id);
    },
  },
});
</script>
<template>
  <div class="modal fade custom-border-modal" id="serch-messages">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="material-icons group-add-btn">arrow_forward</span
            >Buscar por mensagens
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="search_chat has-search me-0 ms-0">
            <form @submit="(e) => e.preventDefault()">
              <span class="fas fa-search form-control-feedback"></span>
              <input
                class="form-control chat_input"
                id="search-new-chat"
                v-model="searchQuery"
                @input="inputQuery"
                type="text"
                placeholder="Buscar mensagens"
              />
              <button type="reset" id="reset" v-on:click="clearSearch()">
                &times;
              </button>
            </form>
          </div>
          <div class="sidebar">
            <perfect-scrollbar
              id="perfect-search-messages"
              style="min-height: 300px; max-height: 450px"
              class="scroll-area"
            >
              <div
                class="message-welcome-center"
                v-if="items.length == 0 && !isSearching && searchQuery == ''"
              >
                <p>
                  Digite o texto que deseja procurar a
                  <span class="text-highlight">mensagem</span>
                </p>
              </div>
              <div class="message-welcome-center" v-if="isSearching">
                <p>
                  Buscando
                  <span class="text-highlight">{{ searchQuery }}...</span>
                </p>
              </div>
              <div
                class="message-welcome-center"
                v-if="!isSearching && searchQuery !== '' && items.length == 0"
              >
                <p>
                  Nada foi encontrado para
                  <span class="text-highlight">{{ searchQuery }}...</span>
                </p>
              </div>

              <div class="chat-body">
                <ChatMessage
                  v-for="(msg, index) in items"
                  v-bind:key="msg?.msgId"
                  :isSearchModal="true"
                  :msg_item="msg"
                  :chat_opened="chat"
                  :index="index"
                  @cleitontest="loadLastMessages"
                  @getMessagesInChat="getMessagesInChat"
                  @clearSearchMessages="
                    items = [];
                    searchQuery = '';
                  "
                >
                </ChatMessage>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
