<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Message } from "../../../types/Message";
import { Contact } from "../../../types/Contact";
import { formatDateForChat, formatHtmlTextChat } from "../../../helpers";

import ReplyMessage from "./ReplyMessageChatMessage.vue";
import MessageDeletedComponent from "./MessageDeletedComponent.vue";
import SenderNameChatMessage from "./SenderNameChatMessage.vue";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
    chat: {
      type: Object as PropType<Contact>,
      required: true,
    },
    showDeletedMessage: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    return { formatDateForChat, formatHtmlTextChat };
  },
  components: { ReplyMessage, MessageDeletedComponent, SenderNameChatMessage },
  methods: {
    setDeletedMessageView(value: boolean) {
      this.$emit("setDeletedMessageView", value);
    },
    setReply(message: Message) {
      this.$emit("setReply", message);
    },
  },
});
</script>
<template>
  <div
    class="chat-content"
    style="min-width: 200px"
    @dblclick="setReply(message)"
  >
    <ReplyMessage :message="message" @click="$emit('getMessageReplyed')" />
    <MessageDeletedComponent
      :message="message"
      :showDeletedMessage="showDeletedMessage"
      @viewDeletedMessage="setDeletedMessageView(true)"
    />
    <div
      class="message-content"
      :id="'content-' + message.msgId"
      :class="{ 'd-none': message.ack === '-8' && !showDeletedMessage }"
    >
      <template v-if="message.isForwarded">
        <small style="font-size: 7pt"
          ><span class="material-icons">redo</span> <i>Encaminhada</i></small
        >
      </template>
      <div class="download-col">
        <ul>
          <li>
            <div class="image-download-col">
              <a
                :href="`https://www.google.com/maps/search/?api=1&query=${message.lat}%2C${message.lng}`"
                target="_blank"
              >
                <img
                  :src="
                    message.messageSocket?.message?.locationMessage
                      ?.jpegThumbnail !== undefined
                      ? 'data:image/png;base64,' +
                        message.messageSocket?.message?.locationMessage
                          ?.jpegThumbnail
                      : 'https://i.imgur.com/pr5upRD.png'
                  "
                  alt=""
                  style="width: 200px; height: 200px; object-fit: cover"
                />
              </a>
            </div>
            <div v-html="formatHtmlTextChat(message.loc || '')"></div>
          </li>
        </ul>
      </div>
      <div class="chat-time">
        <div>
          <div class="time">
            <i class="fas fa-clock"></i>
            {{ formatDateForChat(message.timestamp) }}
          </div>
        </div>
      </div>
    </div>
    <div class="message-reaction" v-if="message.reactions">
      <h6>{{ message.reactions }}</h6>
    </div>
    <SenderNameChatMessage :message="message" :chat="chat" />
  </div>
</template>
