<script>
export default {
  props: ["message", "showDeletedMessage"],
  methods: {
    viewDeletedMsg() {
      this.$emit("viewDeletedMessage");
    },
  },
};
</script>
<template>
  <div
    class="message-content"
    :class="{ 'd-none': message.ack !== '-8' || showDeletedMessage }"
    :id="'viewmsg-' + message.msgId"
  >
    <i>Mensagem apagada</i>
    <div @click="viewDeletedMsg" style="cursor: pointer">
      <span class="material-icons">expand_more</span> Ver mensagem
    </div>
  </div>
</template>
