<script lang="ts">
import { defineComponent } from "vue";
import { api } from "../../../service/api";
import { useStore } from "../../../stores/dataStore";

export default defineComponent({
  setup() {
    return { data: useStore() };
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async requestMessage() {
      try {
        const { data } = await api.get(
          `/message/replyed/${this.message.quotedMsgId}`
        );
        await this.data.editMessage(
          this.message.contactId,
          this.message.msgId,
          {
            quotedMsg: data,
          }
        );
        (this.message as any).quotedMsg = data;
      } catch {
        undefined;
      }
    },
  },
  computed: {
    formatReplyMessage() {
      const reply = this.message.quotedMsg;
      if (!this.message.quotedMsg) {
        this.requestMessage();
        return "Mensagem não encontrada";
      } else if (reply.type == "image") {
        if (reply.content) {
          return "🖼️ Imagem " + reply.content;
        } else {
          return "🖼️ Imagem";
        }
      } else if (reply.type == "video") {
        if (reply.content) {
          return "🎥 Video" + reply.content;
        } else {
          return "🎥 Video";
        }
      } else if (reply.type == "document") {
        return "Arquivo";
      } else if (reply.type == "audio") {
        return "🔊 Áudio";
      } else if (reply.type == "ptt") {
        return "🔊 Mensagem de voz";
      } else if (reply.type == "vcard") {
        return "📞 Contato";
      } else {
        return reply.content;
      }
    },
  },
});
</script>
<template>
  <!-- @click="getMessageReplyed" -->
  <div
    class="reply-content"
    v-if="message.quotedMsgId"
    :id="'reply-' + message.quotedMsgId"
  >
    <div class="download-col" v-if="message?.quotedMsg?.type == 'image'">
      <ul>
        <li>
          <div class="image-download-col">
            <a
              :href="
                message.quotedMsg.path?.includes('base64')
                  ? message.quotedMsg.path
                  : 'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
                    message.quotedMsg.path
              "
              data-fancybox="gallery"
              class="fancybox"
            >
              <img
                v-lazy="{
                  src: message.quotedMsg.path?.includes('base64')
                    ? message.quotedMsg.path
                    : 'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
                      message.quotedMsg.path,
                }"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 15px;
                  opacity: 0.7;
                "
              />
            </a>
          </div>
          <div v-if="message.quotedMsg?.content">
            {{ message.quotedMsg.content }}
          </div>
        </li>
      </ul>
    </div>
    <div v-else>
      {{ formatReplyMessage }}
    </div>
  </div>
</template>
